.list-group {
  &.lg-odd-black {
    .list-group-item:nth-child(odd) {
      background-color: @list-group-hover-bg;
    }
  }

  &.lg-even-black {
    .list-group-item:nth-child(even) {
      background-color: @list-group-hover-bg;
    }
  }
}

.lgi-heading {
  color: #000;
}

.lgi-text {
  color: @text-muted;
}