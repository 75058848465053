.four-zero {
  background-color: @m-bluegray;
}

.fz-block {
  background: #557382;
  color: #ECF0F1;

  h2 {
    color: #ECF0F1;
  }
}

.fzb-links {
  & > a {
    color: #ECF0F1;
  }
}
