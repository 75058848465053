.tab-nav {
  box-shadow: inset 0 -2px 0 0 #eee;

  li {
    & > a {
      color: #7a7a7a;
    }

    &.active {
      & > a {
        color: #000;
      }
    }
  }

  &:not([data-tab-color]) {
    & > li > a:after {
      background: @m-blue;
    }
  }

  &[data-tab-color="green"] {
    & > li > a:after {
      background: @m-green;
    }
  }

  &[data-tab-color="red"] {
    & > li > a:after {
      background: @m-red;
    }
  }

  &[data-tab-color="teal"] {
    & > li > a:after {
      background: @m-teal;
    }
  }

  &[data-tab-color="amber"] {
    & > li > a:after {
      background: @m-amber;
    }
  }

  &[data-tab-color="black"] {
    & > li > a:after {
      background: @m-black;
    }
  }

  &[data-tab-color="cyan"] {
    & > li > a:after {
      background: @m-cyan;
    }
  }
}