.twitter-typeahead {
  width: 100%;

  .tt-menu {
    min-width: 200px;
    background: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    display: none;
    .animated(fadeIn, 300ms);

    &.tt-open:not(.tt-empty) {
      display: block;
    }
  }

  .tt-suggestion {
    padding: 8px 17px;
    color: #333;
    cursor: pointer;
  }

  .tt-suggestion:hover,
  .tt-cursor {
    background-color: rgba(0, 0, 0, 0.075);
  }

  .tt-hint {
    color: #818181 !important;
  }
}