.mCSB_scrollTools {
  width: 5px;

  .mCSB_dragger_bar {
    border-radius: 0 !important;
  }

  &.mCSB_scrollTools_horizontal,
  &.mCSB_scrollTools_vertical {
    margin: 0 !important;
  }

  &.mCSB_scrollTools_horizontal {
    height: 10px;
  }
}

.mCSB_inside > .mCSB_container {
  margin-right: 0;
}
