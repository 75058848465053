/* Draggable items from the playlist reorder page */
body.dragging, body.dragging * {
  cursor: move !important;
}

.dragged {
  position: absolute;
  opacity: 0.5;
  z-index: 2000;
}

table.sortable {
  cursor: pointer;

  tr.placeholder {
    display: block;
    background: @brand-primary;
    position: relative;
    margin: 0;
    padding: 0;

    &::before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border: 5px solid transparent;
      border-right-width: 5px;
      border-right-style: solid;
      border-right-color: transparent;
      border-left-color: transparent;
      border-left-color: @brand-primary;
      margin-top: -5px;
      left: 0;
      border-right: none;
    }
  }
}