.card {
  background: #fff;

  .card-header {
    h2 {
      small {
        color: #AEAEAE;
      }
    }

    &.ch-alt {
      &:not([class*="bgm-"]) {
        background-color: #f7f7f7;
      }
    }
  }
}

.card-light {
  .card-header {
    h2, h2 small {
      color: #fff;
    }
  }
}