#radio-player-controls {
  display: none;

  &.jp-state-playing {
    display: inline-block;
  }

  .progress {
    height: 25px;
  }
}

#radio-embedded-controls {
  display: none;

  &.jp-state-playing {
    display: block;
    padding-top: 5px;
  }

  .progress {
    height: 15px;
  }
}

#radio-player {
  display: none;
}

.btn-audio {
  i:before {
    font-size: 35px;
    vertical-align: middle;
  }
}

.nowplaying {
  h4, h6 {
    margin: 0;
  }
  h4 {
    font-size: 14px;
  }
  h6 {
    font-size: 11px;
    margin-bottom: 2px;
  }

  .nowplaying-progress {
    font-size: 10px;
    color: @list-group-disabled-text-color;
  }
}