/*-------------------------
  Font Icon Family
--------------------------*/
@font-icon-md: 'Material-Design-Iconic-Font';

/*-------------------------
  Grid System
--------------------------*/
@container-large-desktop: 100%;
@container-tablet: 100%;
@container-desktop: 100%;

/*-------------------------
  Typography
--------------------------*/
@font-family-sans-serif: roboto;
@font-size-base: 13px;
@link-hover-decoration: none;

/*-------------------------
  Border Radius
--------------------------*/
@border-radius-base: 2px;
@border-radius-large: 4px;
@border-radius-small: 2px;

/*-------------------------
  Tabs
--------------------------*/
@nav-tabs-border-color: #fff;
@nav-tabs-active-link-hover-border-color: #fff;
@nav-tabs-active-link-hover-bg: transparent;

/*-------------------------
  Form
--------------------------*/
@input-border-radius: 0;
@input-height-large: 40px;
@input-height-base: 35px;
@input-height-small: 30px;
@input-bg: transparent;

/*-------------------------
  Table
--------------------------*/
@table-bg: transparent;
@table-cell-padding: 15px;
@table-condensed-cell-padding: 10px;
@table-bg-hover: @table-bg-accent;

/*-------------------------
  Input Group
--------------------------*/
@input-group-addon-bg: transparent;
@input-group-addon-border-color: transparent;

/*-------------------------
  Pagination
--------------------------*/
@pager-border-radius: 5px;

/*-------------------------
  Popover
--------------------------*/
@popover-fallback-border-color: transparent;
@popover-border-color: transparent;

/*-------------------------
  Dropdown
--------------------------*/
@dropdown-fallback-border: transparent;
@dropdown-border: transparent;
@dropdown-divider-bg: '';
@dropdown-link-hover-bg: rgba(0, 0, 0, 0.075);
@dropdown-link-color: #333;
@dropdown-link-hover-color: #333;
@dropdown-link-disabled-color: #e4e4e4;
@dropdown-divider-bg: rgba(0, 0, 0, 0.08);
@dropdown-link-active-color: #333;
@dropdown-link-active-bg: rgba(0, 0, 0, 0.075);
@zindex-dropdown: 9;
@dropdown-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);

/*-------------------------
  Thumbnail
--------------------------*/
@thumbnail-caption-padding: 10px 15px;
@thumbnail-padding: 3px;

/*-------------------------
  Alerts
--------------------------*/
@alert-success-border: transparent;
@alert-info-border: transparent;
@alert-warning-border: transparent;
@alert-danger-border: transparent;
@alert-inverse-border: transparent;

@alert-success-bg: @m-green;
@alert-info-bg: @m-blue;
@alert-warning-bg: @m-amber;
@alert-danger-bg: @m-red;
@alert-inverse-bg: #333;

@alert-success-text: #fff;
@alert-info-text: #fff;
@alert-warning-text: #fff;
@alert-danger-text: #fff;
@alert-inverse-text: #fff;

/*-------------------------
  Form Validations
--------------------------*/
@state-success-text: lighten(@m-green, 8%);
@state-warning-text: lighten(@m-orange, 8%);
@state-danger-text: lighten(@m-red, 8%);

@state-success-bg: lighten(@m-green, 8%);
@state-warning-bg: lighten(@m-orange, 8%);
@state-danger-bg: lighten(@m-red, 8%);

/*-------------------------
  Buttons
--------------------------*/
@border-radius-base: 2px;
@border-radius-large: 2px;
@border-radius-small: 2px;
@btn-font-weight: 400;

/*-------------------------
  Carousel
--------------------------*/
@carousel-caption-color: #fff;

/*-------------------------
  Modal
--------------------------*/
@modal-content-fallback-border-color: transparent;
@modal-content-border-color: transparent;
@modal-backdrop-bg: #000;
@modal-header-border-color: transparent;
@modal-title-line-height: transparent;
@modal-footer-border-color: transparent;
@zindex-modal-background: 11;

/*-------------------------
  Tooltips
--------------------------*/
@tooltip-opacity: 1;

/*-------------------------
  Popover
--------------------------*/
@zindex-popover: 9;
@popover-title-bg: #fff;
@popover-border-color: #fff;
@popover-fallback-border-color: #fff;

/*-------------------------
  Breadcrumbs
--------------------------*/
@breadcrumb-bg: transparent;
@breadcrumb-padding-horizontal: 20px;
@breadcrumb-active-color: #7c7c7c;

/* ------------------------
    List Group
--------------------------*/
@list-group-active-color: #000;
@list-group-disabled-color: #B5B4B4;
@list-group-disabled-bg: #fff;
@list-group-disabled-text-color: #B5B4B4;
@list-group-bg: transparent;

/*-------------------------
  Badges
--------------------------*/
@badge-border-radius: 2px;
@badge-font-weight: 400;

/*-------------------------
  Material Colors
--------------------------*/
@m-black: #000000;
@m-blue: #2196F3;
@m-red: #F44336;
@m-deeppurple: #673AB7;
@m-lightblue: #03A9F4;
@m-cyan: #00BCD4;
@m-teal: #009688;
@m-green: #4CAF50;
@m-lightgreen: #8BC34A;
@m-lime: #CDDC39;
@m-yellow: #FFEB3B;
@m-amber: #FFC107;
@m-orange: #FF9800;
@m-deeporange: #FF5722;
@m-gray: #9E9E9E;
@m-bluegray: #607D8B;
@m-indigo: #3F51B5;
@m-pink: #E91E63;
@m-brown: #795548;

/*-------------------------
  Bootstrap Branding
--------------------------*/
@brand-primary: @m-blue;
@brand-success: @m-green;
@brand-info: @m-cyan;
@brand-warning: @m-orange;
@brand-danger: @m-red;
@app-gray: #F7F7F7;

/*-------------------------
  Colors
--------------------------*/
@ace: #F7F7F7;

/*-------------------------
  Blocks
--------------------------*/
@sidebar-left-width: 268px;
@sidebar-right-width: 280px;
@footer-height: 75px;
@header-height: 70px;