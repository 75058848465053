.tab-nav {
  list-style: none;
  padding: 0;
  white-space: nowrap;
  margin: 0;
  overflow: auto;
  width: 100%;

  li {
    display: inline-block;
    vertical-align: top;

    & > a {
      display: inline-block;
      text-transform: uppercase;
      position: relative;
      width: 100%;
      .transition(all);
      .transition-duration(250ms);
      font-weight: 500;

      &:after {
        content: "";
        height: 2px;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        .transition(all);
        .transition-duration(250ms);
        .scale(0);
      }

      @media (min-width: @screen-sm-min) {
        padding: 15px;
      }

      @media (max-width: @screen-sm-min) {
        padding: 15px 8px;
      }
    }

    &.active {
      & > a {

        &:after {
          .scale(1);
        }
      }
    }
  }

  &.tab-nav-right {
    text-align: right;
  }

  &.tn-justified {
    & > li {
      display: table-cell;
      width: 1%;
      text-align: center;
    }
  }

  &.tn-icon {
    & > li {
      .zmdi {
        font-size: 22px;
        line-height: 100%;
        min-height: 25px;
      }
    }
  }
}

.tab-content {
  padding: 20px 0;
}
