#profile-main {
  .pm-overview {
    @media (min-width: @screen-sm-min) {
      background: #f8f8f8;
      border-right: 1px solid #eee;
    }

    @media (max-width: @screen-xs-max) {
      background: #333;
    }
  }

  .pmo-pic {
    .pmo-stat {
      @media (min-width: @screen-sm-min) {
        background: @m-amber;
      }
    }
  }
}

.c-timeline {
  @media (max-width: @screen-xs-max) {
    background: @body-bg;
    box-shadow: none;

    .tab-nav {
      background: #fff;
      box-shadow: 0 1px 1px rgba(0, 0, 0, .15);
    }
  }
}

.timeline {
  position: relative;

  @media (min-width: @screen-sm-min) {
    padding: 50px;
    padding-left: 100px;
  }

  @media (max-width: @screen-xs-max) {
    margin-top: 30px;
  }
}

.t-view {
  border: 1px solid #eee;
  position: relative;
  margin-bottom: 35px;

  @media (max-width: @screen-xs-max) {
    background: #fff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .15);
  }

  .tv-header {
    padding: 16px 18px;
    border-bottom: 1px solid #eee;
    background: #F9F9F9;

    .actions {
      position: absolute;
      top: 5px;
      right: 10px;
    }
  }

  .tv-body {
    padding: 23px 25px;

    .tvb-lightbox {
      margin: 0 -8px 15px;

      [data-src] {
        padding: 0 5px;
        margin-bottom: 5px;
      }
    }
  }

  .tvh-user {
    display: block;

    img {
      width: 46px;
      height: 46px;
      border-radius: 50%;
    }
  }

  &:before {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    left: -70px;
    top: 0;
    border: 3px solid #FFF;
    text-align: center;
    font-size: 16px;
    line-height: 34px;
    color: #FFF;
    font-family: @font-icon-md;
    z-index: 1;

  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: -50px;
    width: 1px;
    height: ~"calc(100% + 37px)";

  }

  &[data-tv-type="text"] {
    &:before {
      content: "\f24f";
      background: @m-cyan;
      box-shadow: 0 0 0 1px @m-cyan;
    }

    &:after {
      background: @m-cyan;
    }
  }

  &[data-tv-type="image"] {
    &:before {
      content: "\f17f";
      background: @m-green;
      box-shadow: 0 0 0 1px @m-green;
    }

    &:after {
      background: @m-green;
    }
  }

  &[data-tv-type="video"] {
    &:before {
      content: "\f3a9";
      background: @m-amber;
      box-shadow: 0 0 0 1px @m-amber;
    }

    &:after {
      background: @m-amber;
    }
  }

  .tvb-stats {
    margin: 10px 0 20px;

    & > li {
      display: inline-block;
      padding: 5px 10px 6px;
      border: 1px solid #ccc;
      margin-right: 2px;

      i {
        font-size: 15px;
        line-height: 100%;
        vertical-align: top;
        margin-top: 2px;
      }

      &.tvbs-comments {
        border-color: @m-green;
        color: @m-green;
      }

      &.tvbs-likes {
        border-color: @m-lightblue;
        color: @m-lightblue;
      }

      &.tvbs-views {
        border-color: @m-orange;
        color: @m-orange;
      }
    }
  }
}

.tv-comments {

  .tvc-lists {
    padding: 0;
    list-style: none;
    margin: 0;

    & > li {
      padding: 15px 20px;
      margin: 0;
      border-top: 1px solid #eee;
    }
  }
}

.tvc-more {
  color: #333;
  display: block;
  margin-bottom: -10px;

  &:hover {
    color: #000;
  }

  i {
    vertical-align: middle;
    margin-right: 5px;
  }
}

.p-menu {
  & > li {
    &:not(.active) > a {
      color: #4285F4;

      &:hover {
        color: #333;
      }
    }

    &.active > a {
      color: #000;
    }
  }

  .pms-inner {
    i {
      color: #333;
    }

    input[type="text"] {
      @media (min-width: @screen-sm-min) {
        border: 1px solid #fff;
        background: transparent;

        &:focus {
          border-color: #DFDFDF;
        }
      }
    }
  }
}
