.sweet-alert {
  border-radius: 2px;
  padding: 35px;
  font-family: @font-family-base;

  h2 {
    font-size: 16px;
    position: relative;
    z-index: 1;
    color: #333;
    line-height: 100%;
    margin: 0 0 15px;
    font-weight: 500;
  }

  p {
    font-size: 13px;
    font-weight: 400;
  }

  button {
    padding: 6px 12px;
    border-radius: 2px;
    box-shadow: none !important;
    margin: 26px 1px 0 1px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 400;
    margin-top: 30px;
  }

  .sa-icon {
    margin-top: 0;
    margin-bottom: 30px;
  }
}
