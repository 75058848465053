.checkbox, .radio {
  input {
    &:checked + .input-helper {
      &:before {
        border-color: @m-teal;
      }
    }
  }

  .input-helper {
    &:before {
      border: 2px solid #7a7a7a;
    }
  }
}

.checkbox {
  input {
    &:checked + .input-helper {
      &:before {
        background-color: @m-teal;
      }
    }
  }

  .input-helper {
    &:after {
      font-size: 12px;
      color: #fff;
    }
  }
}

.radio {
  .input-helper {
    &:after {
      background: @m-teal;
    }
  }
}

.checkbox-light,
.radio-light {
  .input-helper {
    &:before {
      border-color: #fff;
    }
  }
}

.checkbox-light {
  input {
    &:checked + .input-helper {
      &:before {
        border-color: #fff;
        background-color: #fff;
      }

      &:after {
        color: #333;
      }
    }
  }
}

.fg-line {
  .form-control {
    &:disabled {
      color: #9d9d9d;
    }
  }
}

.fg-float {
  .form-control {
    .placeholder(#fff);
  }

  .fg-label {
    color: #959595;
  }
}

.toggle-switch {
  .ts-helper {
    background: rgba(0, 0, 0, 0.26);
  }
}