.lcb-navigation {
  a {
    color: #fff;
    background: rgba(255, 255, 255, .2);
  }
}

.lcb-user {
  border: 5px solid #fff;
}
