.table {
  margin-bottom: 0;

  & > thead > tr > th {
    vertical-align: middle;
    font-weight: 500;
    border-width: 1px;
    text-transform: uppercase;
  }

  &.table-inner {
    border: 0;
  }

  & > thead > tr,
  & > tbody > tr,
  & > tfoot > tr {
    & > th, & > td {
      &:first-child {
        padding-left: 30px;
      }

      &:last-child {
        padding-right: 30px;
      }
    }
  }

  & > thead,
  & > tbody,
  & >tfoot {
    & > tr.input > td,
    & > tr.vertical-align-middle > td {
      vertical-align: middle;
    }
  }

  & > tbody, & > tfoot {
    & > tr {
      &.active,
      &.warning,
      &.info,
      &.success,
      &.danger {
        & > td {
          border: 0;
        }
      }

      &:last-child > td > {
        padding-bottom: 20px;
      }
    }
  }
}

.table-striped {
  td, th {
    border: 0 !important;
  }
}

.table-bordered {
  border-bottom: 0;
  border-left: 0;
  border-right: 0;

  & > tbody > tr {
    & > td, & > th {
      border-bottom: 0;
      border-left: 0;

      &:last-child {
        border-right: 0;
      }
    }
  }

  & > thead > tr > th {
    border-left: 0;

    &:last-child {
      border-right: 0;
    }
  }
}

.table-vmiddle {
  td {
    vertical-align: middle !important;
  }
}

.table-responsive {
  border: 0;
}

