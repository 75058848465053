.block-header {
  & > h2 {
    color: #777;

    & > small {
      color: #9E9E9E;
    }
  }
}

.actions {
  & > li > a,
  & > a {
    & > i {
      color: #adadad;
    }

    &:hover {
      & > i {
        color: #000;
      }
    }
  }

  & > li.open,
  &.open {
    & > a {
      & > i {
        color: #000;
      }

      &:before {
        .scale(1);
        .opacity(1);
      }
    }
  }

  &.actions-alt {
    & > li {
      & > a > i {
        color: #fff;

        &:hover {
          color: #fff;
        }
      }

      &.open {
        & > a {
          & > i {
            color: #fff;
          }
        }
      }
    }
  }

  &.open {
    z-index: 4;
  }
}

.line-wrap {
  .transition(all);
  .transition-duration(300ms);
  margin: 12px 20px;
}

.load-more {
  a {
    background-color: @m-red;
    color: #FFF;

    &:hover {
      background-color: darken(@m-red, 10%);
    }
  }
}

.view-more {
  color: @text-muted;

  &:hover {
    color: @text-color;
  }
}

html {
  &:not(.ismobile) {
    .page-loader {
      background: #fff;
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1000;

      .preloader {
        width: 50px;
        position: absolute;
        left: 50%;
        margin-left: -25px;
        top: 50%;
        margin-top: -55px;
        .animated(fadeIn, 3000ms);

        p {
          white-space: nowrap;
          position: relative;
          left: -9px;
          top: 22px;
          color: #CCC;
        }
      }
    }
  }

  &.ismobile .page-loader {
    display: none;
  }
}

.action-header {
  background-color: #F7F7F7;
}

.ahs-input {
  background-color: #F7F7F7;
}

.ahs-close {
  background: #E2E2E2;
  color: #6B6B6B;

  &:hover {
    opacity: 0.8;
  }
}