.panel-heading {
  padding: 0;
}

.panel-collapse {
  .panel-heading {
    .panel-title {
      & > a {
        color: #000;

        &:after,
        &:before {
          position: absolute;
          .transition(all);
          .transition-duration(300ms);
          .backface-visibility(hidden);
        }

        &:after {
          .scale(0);
        }
      }
    }

    &:not(.active) .panel-title > a {
      &:before {
        background: #eee;
      }
    }

    &:before,
    &:after {
      font-size: 17px;
      left: 0;
      top: 4px;
    }

    &.active {
      .panel-title > a {
        &:after {
          .scale(1);
        }
      }

      &:before {
        .scale-rotate(0, -90deg);
      }
    }
  }
}

.panel-collapse-color(@color) {
  .panel-collapse {
    .panel-heading {
      &.active .panel-title > a {
        &:after {
          background: @color;
        }
      }
    }
  }
}

.panel-group {
  &:not([data-collapse-color]) {
    .panel-collapse-color(@m-blue);
  }

  &[data-collapse-color="red"] {
    .panel-collapse-color(@m-red);
  }

  &[data-collapse-color="green"] {
    .panel-collapse-color(@m-green);
  }

  &[data-collapse-color="amber"] {
    .panel-collapse-color(@m-amber);
  }

  &[data-collapse-color="teal"] {
    .panel-collapse-color(@m-teal);
  }

  &[data-collapse-color="black"] {
    .panel-collapse-color(@m-black);
  }

  &[data-collapse-color="cyan"] {
    .panel-collapse-color(@m-cyan);
  }
}
