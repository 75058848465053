.sidebar {
  position: fixed;
  top: @header-height;
  .transition(all);
  .transition-duration(300ms);
  z-index: 10;
  overflow-y: auto;
  box-shadow: @card-shadow;
}

#sidebar {
  width: @sidebar-left-width;

  @media (max-width: (@screen-md-max + 80)) {
    &.toggled {
      .translate3d(0, 0, 0);
    }
  }
}

.sidebar-alt {
  top: 0;
  height: 100%;

  .translate3d(-@sidebar-left-width, 0, 0);

  &.toggled {
    .translate3d(0, 0, 0);
  }
}

.main-menu {
  list-style: none;
  padding-left: 0;
  margin: 20px 0 0 0;

  a {
    .transition(color);
    .transition-duration(300ms);
  }

  & > li {
    &.menu-alert > a {
      background: @alert-warning-bg;
      color: @alert-warning-text;

      &:hover {
        background: darken(@alert-warning-bg, 5%);
      }
    }

    &.menu-info > a {
      background: @alert-info-bg;
      color: @alert-info-text;

      &:hover {
        background: darken(@alert-info-bg, 5%);
      }
    }

    &.menu-header > a {
      padding: 14px 20px 14px 20px;
      color: @headings-color;
      font-size: 20px;
      font-weight: bold;
    }

    & > a {
      padding: 14px 20px 14px 65px;
      display: block;
      position: relative;

      & > i {
        position: absolute;
        left: 25px;
        font-size: 20px;
        top: 0;
        width: 25px;
        text-align: center;
        padding: 13px 0;
      }
    }
  }
}

.sub-menu {
  & > a {
    position: relative;

    &:before, &:after {
      position: absolute;
      top: 50%;
      margin-top: -11px;
      font-family: @font-icon-md;
      font-size: 17px;
      right: 15px;
      .transition(all);
      .transition-duration(250ms);
    }

    &:before {
      content: "\f278";
      .scale(1);
    }

    &:after {
      content: "\f273";
      .scale(0);
    }
  }

  &.toggled {
    & > a {
      &:before {
        content: "\f278";
        .scale(0);
      }

      &:after {
        content: "\f273";
        .scale(1);
      }
    }
  }

  ul {
    list-style: none;
    display: none;
    padding: 0;

    & > li {
      & > a {
        padding: 8px 20px 8px 65px;
        display: block;
      }

      &:first-child > a {
        padding-top: 14px;
      }

      &:last-child > a {
        padding-bottom: 16px;
      }

      ul {
        font-size: 12px;
        margin: 10px 0;
      }
    }
  }

  &.active {
    & > ul {
      display: block;
    }
  }
}

.ie9 {
  #sidebar {
    @media (max-width: (@screen-md-max + 80)) {
      display: none;

      &.toggled {
        display: block;
      }
    }
  }

  .sidebar-alt {
    display: none;

    &.toggled {
      display: block;
    }
  }

}
