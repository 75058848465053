/*--------------------------------------------------
    Block Header
    Used for Heading outside the Cards.
---------------------------------------------------*/
.block-header {
  @media screen and (min-width: @screen-sm-min) {
    padding: 0 22px;
  }

  @media screen and (max-width: @screen-sm-max) {
    padding: 0 18px;
  }

  margin-bottom: 25px;
  position: relative;

  & > h2 {
    font-size: 15px;
    margin: 0;
    font-weight: 400;
    text-transform: uppercase;

    & > small {
      display: block;
      text-transform: none;
      margin-top: 8px;
      margin-bottom: 20px;
      line-height: 140%;
    }
  }

  .actions {
    position: absolute;
    right: 10px;
    top: -5px;
    z-index: 4;
  }
}

/*-------------------------
    Actions
--------------------------*/

.actions {
  list-style: none;
  padding: 0;
  z-index: 3;
  margin: 0;

  & > li {
    display: inline-block;
    vertical-align: baseline;
  }

  & > li > a,
  & > a {
    width: 30px;
    height: 30px;
    display: inline-block;
    text-align: center;
    padding-top: 5px;

    & > i {
      font-size: 20px;
    }
  }
}

/*-------------------------
    Collapse Menu Icons
--------------------------*/

.line-wrap {
  width: 18px;
  height: 12px;

  .line {
    width: 18px;
    height: 2px;
    .transition(all);
    .transition-duration(300ms);
    background-color: #fff;

    &.center {
      margin: 3px 0;
    }
  }
}

.toggled {
  .line-wrap {
    .rotate(180deg);

    .line {
      &.top {
        width: 12px;
        transform: translateX(8px) translateY(1px) rotate(45deg);
        -webkit-transform: translateX(8px) translateY(1px) rotate(45deg);
      }

      &.bottom {
        width: 12px;
        transform: translateX(8px) translateY(-1px) rotate(-45deg);
        -webkit-transform: translateX(8px) translateY(-1px) rotate(-45deg);
      }
    }
  }
}

/*-------------------------
    Load More
--------------------------*/

.load-more {
  text-align: center;
  margin-top: 30px;

  a {
    padding: 8px 10px 6px;
    display: inline-block;
    border-radius: 2px;
    white-space: nowrap;

    i {
      font-size: 20px;
      vertical-align: middle;
      position: relative;
      margin-top: -2px;
    }
  }
}

/*-------------------------
    View More
--------------------------*/

.view-more {
  display: block;
  text-align: center;
  padding: 10px 10px 11px;
  line-height: 100%;
  font-size: 11px;
  margin-top: 20px;
  text-transform: uppercase;
}

/*----------------------------------------
    Action Header
-----------------------------------------*/

.action-header {
  padding: 25px 30px;
  line-height: 100%;
  position: relative;
  z-index: 1;
  min-height: 65px;

  .actions {
    position: absolute;
    top: 18px;
    right: 17px;
    z-index: 10;
  }
}

.ah-label {
  font-size: 15px;
}

.ah-search {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 11;
  background-color: #454E53;
  display: none;
}

.ahs-input {
  border: 0;
  padding: 0 26px;
  height: 100%;
  font-size: 15px;
  width: 100%;
  line-height: 60px;
}

.ahs-close {
  font-style: normal;
  position: absolute;
  top: 23px;
  right: 25px;
  font-size: 17px;
  line-height: 100%;
  text-align: center;
  cursor: pointer;
  width: 18px;
  height: 18px;
  border-radius: 50%;
}

/*----------------------------------
    Backdrop
-----------------------------------*/

.ma-backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9;
  cursor: pointer;
}

/*----------------------------------
    Avatar
-----------------------------------*/

[class*="avatar-img"] {
  border-radius: 50%;
}

.avatar-img {
  width: 42px;
}

/*----------------------------------
    Navigation
-----------------------------------*/

.nav>li>a:hover, .nav>li>a:focus {
  background-color: rgba(0, 0, 0, 0.1);
}