.modal {
  .modal-content {
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.31);
    border-radius: 3px;
    border: 0;
  }

  .modal-header {
    padding: 23px 26px;
  }

  .modal-body {
    padding: 0 26px 10px;
  }

  .modal-footer {
    .btn-link {
      font-size: 14px;
      color: #000;
      font-weight: 500;

      &:hover {
        background-color: #eee;
      }
    }
  }
}