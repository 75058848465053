.alert {
  padding-left: 30px;
  font-size: 13px;

  span {
    cursor: pointer;
  }

  &:not(.alert-dismissible) {
    padding-right: 30px;
  }

  &.alert-dismissable {
    padding-right: 44px;
  }
}

.alert-link {
  color: #fff !important;
}
