.public-page {
  height: 100vh;
}

.public-block {
  background: @body-bg;
  border-radius: 2px;
  position: absolute;
  top: 50%;
  margin-top: -100px;
  padding: 25px;
  height: 200px;
  width: 500px;
  left: 50%;
  margin-left: -250px;

  h3 {
    margin-top: 0;
    margin-bottom: 0;
  }

  h2 {
    font-size: 130px;
    line-height: 100%;
    font-weight: 100;
  }

  @media (max-width: @screen-xs-max) {
    width: ~"calc(100% - 40px)";
    left: 20px;
    margin-left: 0;
    height: 260px;
    margin-top: -130px;

    h2 {
      font-size: 90px;
    }
  }

  small {
    display: block;
    font-size: 26px;
    margin-top: -10px
  }

  .stations {
    margin: 20px 0;
  }
}