html {
  &:not(.ie9) {
    .mCS-minimal-dark {
      &.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
        background: rgba(0, 0, 0, 0.4);
      }

      &.mCSB_scrollTools_onDrag .mCSB_dragger .mCSB_dragger_bar {
        background: rgba(0, 0, 0, 0.4);
      }
    }
  }

  &.ie9 {
    .mCS-minimal-dark {
      &.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
        background: #000000;
      }

      &.mCSB_scrollTools_onDrag .mCSB_dragger .mCSB_dragger_bar {
        background: #000000;
      }
    }
  }
}