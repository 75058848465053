.hi-logo {
  a {
    color: #fff;
  }
}

.hi-trigger {
  display: none !important;

  &:before {
    background: rgba(255, 255, 255, 0.22);
  }
}

.hi-menu {
  & > li {
    & > a {
      color: #fff;
      .transition(background-color);
      .transition-duration(250ms);
      border-radius: 2px;

      & > .him-counts {
        background: @m-red;
      }
    }

    &.toggled > a,
    &:hover > a {
      background-color: rgba(0, 0, 0, 0.08);
    }
  }
}

#header {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);

  background-position: left center;
  background-repeat: no-repeat;
  background-size: 500px 100px;

  .hi-logo a {
    padding: 0px 10px;
    margin: -5px 0;
  }

  .hi-trigger {
    @media (max-width: (@screen-md-max + 80)) {
      display: inline-block !important;
    }
  }
}

.h-search-wrap {
  background: #fff;

  input[type="text"] {
    background-color: darken(@ace, 3%);
  }

  .hsw-close {
    &:hover {
      background-color: darken(@ace, 8%);
    }
  }
}

#header-alt {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);

  @media (min-width: @screen-md-min) {
    &:before {
      background: rgba(0, 0, 0, 0.04);
    }
  }

  .hi-trigger {
    @media (max-width: @screen-sm-max) {
      display: inline-block !important;
    }
  }

  .ha-search {
    input[type="text"] {
      border-bottom: 1px solid rgba(255, 255, 255, 0.24);
      color: #fff;
      .placeholder(#fff);

      &.ie9-placeholder {
        color: #fff !important;
      }
    }

    .fg-line {
      &:after {
        background: @m-yellow;
      }

      &:before {
        color: #fff;
      }
    }
  }
}

.ha-menu {
  @media (min-width: @screen-md-min) {
    & > ul {
      & > li {
        &:not(.active) > *:not(ul) {
          color: rgba(255, 255, 255, 0.6);
        }

        &.active > a {
          color: #fff;
          box-shadow: inset 0px -3px 0 0px @m-yellow;
        }
      }
    }
  }
}