.bootstrap-select {
  width: 100%;

  & > .btn-default {
    border-bottom: 1px solid #e0e0e0 !important;
  }

  .bs-searchbox {
    background: @ace;

    &:before {
      top: 0;
    }
  }
}
