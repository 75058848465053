.chosen-container-single {
  .chosen-search {
    background: @ace;
  }
}

.chosen-container-multi {
  .chosen-choices {
    li {
      &.search-choice {
        background: darken(@ace, 5%);
      }
    }
  }
}

.search-choice-close {
  &:before {
    color: #9C9C9C;
    background: #EAEAEA;
  }
}


