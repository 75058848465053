.btn {
  &:not(.btn-link) {
    .z-depth(1);
  }
}

.btn-group, .btn-group-vertical {
  &:not(.bootstrap-select) {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  }
}

.btn-link {
  color: #797979;

  &:hover {
    color: #0a0a0a;
  }
}

.btn-float {

  &:not(.bgm-white):not(.bgm-gray) {
    & > i {
      color: #fff;
    }
  }
}