.bootstrap-datetimepicker-widget {
  bottom: auto !important;
  top: 0 !important;

  a[data-action] {
    color: @m-teal;
  }
}

.timepicker-picker {
  table {
    tbody tr + tr:not(:last-child) {
      background: @m-teal;
    }
  }

  .btn,
  .btn:hover {
    color: #333;
  }
}

.datepicker {
  table {
    thead {
      tr {
        &:first-child {
          th {
            background: @m-teal;

            &:hover {
              background: @m-teal;
            }
          }
        }

        &:last-child {
          th {
            &:not(:only-child) {
              background: darken(@m-teal, 3%);
            }
          }
        }
      }

      td {
        &.active {
          &:before {
            background-color: @m-teal;
          }
        }
      }
    }
  }
}

.datepicker-months .month,
.datepicker-years .year,
.timepicker-minutes .minute,
.timepicker-hours .hour {
  &.active {
    background: @m-teal;
  }
}