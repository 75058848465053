.sidebar {
  background: #fff;
  height: ~"calc(100% - 70px)";
}

#sidebar {
  @media (max-width: (@screen-md-max + 80)) {
    .translate3d(-(@sidebar-left-width + 20), 0, 0);
    box-shadow: 0 0 20px rgba(14, 18, 21, 0.38);
  }
}

.main-menu {
  margin: 20px 0 0 0;

  a:hover,
  .active > a,
  a.active {
    color: #262626;
  }

  & > li {
    & > a {
      font-weight: 500;
      color: #4C4C4C;
    }

    & > a:hover,
    &.active > a {
      background-color: #F4F4F4;
    }
  }
}

.sub-menu {
  ul {
    & > li {
      & > a {
        font-weight: 500;
        color: #989898;
      }

      ul {
        background-color: @app-gray;
      }
    }
  }
}