.panel {
  box-shadow: none;
  border: 0;
}

.panel-title {
  & > a {
    padding: 10px 15px;
    display: block;
    font-size: 13px;
  }
}

.panel-collapse {
  .panel-heading {
    position: relative;

    .panel-title {
      & > a {
        padding: 8px 5px 16px 30px;
        position: relative;

        &:before {
          bottom: 0;
          left: 0;
          height: 2px;
          width: 100%;
          content: "";
        }
      }
    }

    &:before,
    &:after {
      font-family: @font-icon-md;
      position: absolute;
      .transition(all);
      .transition-duration(300ms);
      .backface-visibility(hidden);
    }

    &:before {
      content: "\f278";
      .scale(1);
    }

    &:after {
      .scale(0);
      content: "\f273";
    }

    &.active {
      &:after {
        .scale(1);
      }
    }
  }

  .panel-body {
    border-top: 0 !important;
    padding-left: 5px;
    padding-right: 5px;
  }
}